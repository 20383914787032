import React, { useState } from 'react';
import './LandingPage.css';
import logo from '../../assets/logo.svg';
import {
  getAuth,
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { app } from '../../firebase/firebaseConfig';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const db = getFirestore(app);

  const verifyClient = async (uid) => {
    try {
      const clientDocRef = doc(db, 'Clients', uid);
      const clientDoc = await getDoc(clientDocRef);
      if (!clientDoc.exists()) {
        throw new Error('Unauthorized access: You are not registered as a client.');
      }
    } catch (error) {
      throw error;
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    try {
      // Set persistence to local (default behavior)
      await setPersistence(auth, browserLocalPersistence);

      // Sign in with email and password
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid;

      // Verify if the user exists in the Clients collection
      await verifyClient(userId);

      console.log('Login successful!');
      navigate('/dashboard'); // Redirect to the dashboard
    } catch (error) {
      console.error('Login error:', error);
      setErrorMessage(error.message || 'Invalid email or password. Please try again.');
    }
  };

  return (
    <div className="landing-page">
      {/* Header Section */}
      <div className="landing-header">
        <img src={logo} alt="Logo" className="landing-logo" />
        <div className="landing-text">
          <h1>AigencyOne Technology</h1>
          <h2>Client Portal</h2>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="landing-main">
        <div className="login-container">
          <h2 className="login-title">Welcome Back</h2>
          <form className="login-form" onSubmit={handleLogin}>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <button type="submit" className="login-button">Login</button>
          </form>

          <div className="login-footer">
            <a href="/forgot-password" className="forgot-password-link">Forgot Password?</a>
            <p>
              Don’t have an account? <a href="/sign-up" className="sign-up-link">Sign Up</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
