import React, { useState, useEffect } from 'react';
import Sidebar from '../Sidebar/Sidebar'; // Adjust the path if necessary
import '../Dashboard/Dashboard.css'; // Shared styles from Dashboard
import './Projects.css'; // Specific styles for Projects
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { app } from '../../firebase/firebaseConfig'; // Firebase configuration

function Projects() {
  const [projects, setProjects] = useState([]); // State to store project data
  const [loading, setLoading] = useState(true); // Loading state
  const [errorMessage, setErrorMessage] = useState(''); // Error message state
  const auth = getAuth(app); // Firebase Authentication
  const db = getFirestore(app); // Firestore instance

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const user = auth.currentUser; // Get the currently logged-in user
        if (!user) {
          throw new Error('No authenticated user found.');
        }

        const userId = user.uid; // Get the user's UID

        // Query the Projects collection for the client's projects
        const projectsRef = collection(db, 'Projects');
        const q = query(projectsRef, where('clientId', '==', userId));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          throw new Error('No projects found for this client.');
        }

        const projectsData = querySnapshot.docs.map((doc) => {
          const project = doc.data();

          // Parse dates: Check if the fields are strings and convert them to Date objects
          const parsedStartDate = project.startDate
            ? new Date(project.startDate) // Convert string to Date
            : null;
          const parsedEndDate = project.endDate
            ? new Date(project.endDate) // Convert string to Date
            : null;

          // Parse milestones' dueDates
          const parsedMilestones = project.milestones?.map((milestone) => ({
            ...milestone,
            dueDate: milestone.dueDate ? new Date(milestone.dueDate) : null, // Convert string to Date
          }));

          return {
            id: doc.id,
            ...project,
            startDate: parsedStartDate,
            endDate: parsedEndDate,
            milestones: parsedMilestones,
          };
        });

        setProjects(projectsData); // Set project data in state
      } catch (error) {
        console.error('Error fetching projects:', error);
        setErrorMessage(error.message || 'Failed to fetch projects.');
      } finally {
        setLoading(false); // Stop loading spinner
      }
    };

    fetchProjects();
  }, [auth, db]);

  if (loading) {
    return <div className="dashboard-container">Loading...</div>;
  }

  if (errorMessage) {
    return (
      <div className="dashboard-container">
        <div className="error-message">{errorMessage}</div>
      </div>
    );
  }

  return (
    <div className="dashboard-container">
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div className="dashboard-content">
        <h1 className="dashboard-title">Projects</h1>
        <div className="dashboard-section projects-container">
          {projects.length > 0 ? (
            <ul className="project-list">
              {projects.map((project) => (
                <li key={project.id} className="project-item">
                  <h3>{project.name}</h3>
                  <p>Status: {project.status}</p>
                  <p>
                    Start Date:{' '}
                    {project.startDate
                      ? project.startDate.toLocaleDateString()
                      : 'Not Available'}
                  </p>
                  <p>
                    Due Date:{' '}
                    {project.endDate
                      ? project.endDate.toLocaleDateString()
                      : 'Not Available'}
                  </p>
                  <p>Description: {project.description || 'No description available'}</p>

                  {/* Milestones */}
                  {project.milestones?.length > 0 ? (
                    <div className="milestones">
                      <h4>Milestones</h4>
                      <ul className="milestone-list">
                        {project.milestones.map((milestone, index) => (
                          <li key={index} className="milestone-item">
                            <p>Name: {milestone.name}</p>
                            <p>Status: {milestone.status}</p>
                            <p>Amount: ${milestone.amount}</p>
                            <p>
                              Due Date:{' '}
                              {milestone.dueDate
                                ? milestone.dueDate.toLocaleDateString()
                                : 'Not Available'}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <p>No milestones found for this project.</p>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p>No projects found for this account.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Projects;
