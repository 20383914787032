import React from 'react';
import Sidebar from '../Sidebar/Sidebar'; // Adjust the path if necessary
import '../Dashboard/Dashboard.css'; // Shared styles from Dashboard
import './Settings.css'; // Specific styles for Settings

function Settings() {
  return (
    <div className="dashboard-container">
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div className="dashboard-content">
        <h1 className="dashboard-title">Settings</h1>
        <div className="dashboard-section settings-container">
          {/* Example Settings */}
          <div className="settings-item">
            <h2>Profile Settings</h2>
            <p>Update your profile information, email, and password.</p>
            <button className="settings-button">Edit Profile</button>
          </div>

          <div className="settings-item">
            <h2>Notification Settings</h2>
            <p>Manage your notification preferences.</p>
            <button className="settings-button">Update Notifications</button>
          </div>

          <div className="settings-item">
            <h2>Account Settings</h2>
            <p>Deactivate your account or manage linked accounts.</p>
            <button className="settings-button">Manage Account</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
