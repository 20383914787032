import React, { useState } from 'react';
import './LandingPage.css';
import logo from '../../assets/logo.svg';
import { getAuth, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { app } from '../../firebase/firebaseConfig'; // Ensure firebaseConfig.js is properly set up
import { useNavigate } from 'react-router-dom';

function SignUp() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    businessName: '',
    businessEmail: '',
    businessPhone: '',
    brandingColorPrimary: '#000000',
    brandingColorSecondary: '#ffffff',
    brandingColorBackground: '#f4f4f4',
    font: 'Arial',
  });
  const [useSameContact, setUseSameContact] = useState(false);
  const [businessLogo, setBusinessLogo] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const auth = getAuth(app); // Firebase Authentication
  const db = getFirestore(app); // Firestore Database
  const storage = getStorage(app); // Firebase Storage
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    setBusinessLogo(file);
  };

  const handleSameContactToggle = () => {
    setUseSameContact(!useSameContact);
    if (!useSameContact) {
      setFormData((prevData) => ({
        ...prevData,
        businessEmail: formData.email,
        businessPhone: formData.phone,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        businessEmail: '',
        businessPhone: '',
      }));
    }
  };

  const uploadLogoToStorage = async (userId) => {
    try {
      if (!businessLogo) return null;

      const logoRef = ref(storage, `businessLogos/${userId}/${businessLogo.name}`);
      await uploadBytes(logoRef, businessLogo);
      const logoUrl = await getDownloadURL(logoRef);

      return logoUrl;
    } catch (error) {
      console.error('Error uploading logo to storage:', error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Show spinner
    try {
      // Create a new user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, formData.email, 'temporaryPassword123!');
      const userId = userCredential.user.uid;

      // Send password reset email
      await sendPasswordResetEmail(auth, formData.email);

      // Upload logo to Firebase Storage and get the download URL
      const logoUrl = await uploadLogoToStorage(userId);

      // Add client details to Firestore
      const clientData = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        businessName: formData.businessName,
        businessEmail: formData.businessEmail,
        businessPhone: formData.businessPhone,
        brandingColorPrimary: formData.brandingColorPrimary,
        brandingColorSecondary: formData.brandingColorSecondary,
        brandingColorBackground: formData.brandingColorBackground,
        font: formData.font,
        logoUrl: logoUrl || '', // Save the logo URL in Firestore
        createdAt: new Date(),
      };

      await setDoc(doc(db, 'Clients', userId), clientData);

      setSuccessMessage('Sign-up successful! Please check your email to set your password.');
      setErrorMessage('');
    } catch (error) {
      console.error('Error during sign-up:', error);
      setErrorMessage('Sign-up failed. Please try again.');
      setSuccessMessage('');
    } finally {
      setIsSubmitting(false); // Hide spinner
    }
  };

  return (
    <div className="landing-page">
      {/* Header Section */}
      <div className="landing-header">
        <a href="/" className="logo-link">
          <img src={logo} alt="Logo" className="landing-logo" />
        </a>
        <div className="landing-text">
          <h1>AigencyOne Technology</h1>
          <h2>Client Portal</h2>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="landing-main">
        <div className="login-container">
          <h2 className="login-title">Sign Up</h2>
          <form className="login-form" onSubmit={handleSubmit}>
            {/* Personal Details */}
            <div className="form-group">
              <label htmlFor="name">Full Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter your full name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="Enter your phone number"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
            </div>

            {/* Business Details */}
            <div className="form-group">
              <label htmlFor="businessName">Business Name</label>
              <input
                type="text"
                id="businessName"
                name="businessName"
                placeholder="Enter your business name"
                value={formData.businessName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label>
                <input
                  type="checkbox"
                  checked={useSameContact}
                  onChange={handleSameContactToggle}
                />
                Use same email and phone for business
              </label>
            </div>
            <div className="form-group">
              <label htmlFor="businessEmail">Business Email</label>
              <input
                type="email"
                id="businessEmail"
                name="businessEmail"
                placeholder="Enter your business email"
                value={formData.businessEmail}
                onChange={handleInputChange}
                required={!useSameContact}
                disabled={useSameContact}
              />
            </div>
            <div className="form-group">
              <label htmlFor="businessPhone">Business Phone</label>
              <input
                type="tel"
                id="businessPhone"
                name="businessPhone"
                placeholder="Enter your business phone"
                value={formData.businessPhone}
                onChange={handleInputChange}
                required={!useSameContact}
                disabled={useSameContact}
              />
            </div>

            {/* Logo Upload */}
            <div className="form-group">
              <label htmlFor="logoUpload">Upload Business Logo</label>
              <input
                type="file"
                id="logoUpload"
                accept="image/*"
                onChange={handleLogoUpload}
              />
              {businessLogo && (
                <p className="file-info">Selected File: {businessLogo.name}</p>
              )}
            </div>

            {/* Branding Colors */}
            <div className="form-group">
              <label htmlFor="brandingColorPrimary">Primary Branding Color</label>
              <input
                type="color"
                id="brandingColorPrimary"
                name="brandingColorPrimary"
                value={formData.brandingColorPrimary}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="brandingColorSecondary">Secondary Branding Color</label>
              <input
                type="color"
                id="brandingColorSecondary"
                name="brandingColorSecondary"
                value={formData.brandingColorSecondary}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="brandingColorBackground">Background Color</label>
              <input
                type="color"
                id="brandingColorBackground"
                name="brandingColorBackground"
                value={formData.brandingColorBackground}
                onChange={handleInputChange}
                required
              />
            </div>

            {/* Font Preferences */}
            <div className="form-group">
              <label htmlFor="font">Font</label>
              <select
                id="font"
                name="font"
                value={formData.font}
                onChange={handleInputChange}
              >
                <option style={{ fontFamily: 'Arial' }} value="Arial">Arial</option>
                <option style={{ fontFamily: 'Georgia' }} value="Georgia">Georgia</option>
                <option style={{ fontFamily: 'Helvetica' }} value="Helvetica">Helvetica</option>
                <option style={{ fontFamily: 'Verdana' }} value="Verdana">Verdana</option>
                <option style={{ fontFamily: 'Times New Roman' }} value="Times New Roman">Times New Roman</option>
                <option style={{ fontFamily: 'Courier New' }} value="Courier New">Courier New</option>
              </select>
            </div>

            {/* Success and Error Messages */}
            {isSubmitting ? (
              <div className="spinner">Processing...</div>
            ) : (
              <>
                {successMessage && (
                  <div className="success-container">
                    <p className="success-message">{successMessage}</p>
                    <button className="home-button" onClick={() => navigate('/')}>
                      Home
                    </button>
                  </div>
                )}
                {errorMessage && <p className="error-message">{errorMessage}</p>}
              </>
            )}

            {/* Submit Button */}
            {!successMessage && !isSubmitting && (
              <button type="submit" className="login-button">
                Sign Up
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
