import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import Sidebar from '../Sidebar/Sidebar';
import { getFirestore, collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { app } from '../../firebase/firebaseConfig';

function Dashboard() {
  const [userName, setUserName] = useState(''); // State for storing the user's name
  const [projects, setProjects] = useState([]); // State for storing projects
  const [billingInfo, setBillingInfo] = useState(null); // State for storing billing info
  const [loading, setLoading] = useState(true); // Loading state
  const [errorMessage, setErrorMessage] = useState(''); // Error message state
  const auth = getAuth(app);
  const db = getFirestore(app);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const user = auth.currentUser; // Get the currently logged-in user
        if (user) {
          const userId = user.uid; // Get the user's UID

          // Fetch client data
          const clientRef = doc(db, 'Clients', userId);
          const clientSnap = await getDoc(clientRef);

          if (!clientSnap.exists()) {
            throw new Error('Unauthorized access: You are not registered as a client.');
          }

          const clientData = clientSnap.data();
          setUserName(clientData.name); // Set the user's name from Firestore

          // Fetch associated projects
          const projectsRef = collection(db, 'Projects');
          const projectsQuery = query(projectsRef, where('clientId', '==', userId));
          const projectSnapshot = await getDocs(projectsQuery);

          const projectsData = projectSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setProjects(projectsData);

          // Fetch billing information
          const billingRef = collection(db, 'Billing');
          const billingQuery = query(billingRef, where('clientId', '==', userId));
          const billingSnapshot = await getDocs(billingQuery);

          if (!billingSnapshot.empty) {
            const billingData = billingSnapshot.docs[0].data();
            setBillingInfo(billingData);
          }
        } else {
          throw new Error('No authenticated user found.');
        }
      } catch (error) {
        console.error('Access error:', error);

        // Log the user out
        await signOut(auth);

        // Set the error message
        setErrorMessage(error.message || 'Unauthorized access.');

        // Redirect to the home/login page after 2 seconds
        setTimeout(() => navigate('/'), 2000);
      } finally {
        setLoading(false); // Stop loading regardless of success or error
      }
    };

    fetchClientData();
  }, [auth, db, navigate]);

  if (loading) {
    return <div className="dashboard-container">Loading...</div>;
  }

  if (errorMessage) {
    return (
      <div className="dashboard-container">
        <div className="error-message">{errorMessage}</div>
      </div>
    );
  }

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="dashboard-content">
        <h1>Welcome, {userName || 'User'}</h1>

        <div className="dashboard-section">
          <h2>Projects</h2>
          {projects.length > 0 ? (
            <ul className="projects-list">
              {projects.map((project) => (
                <li key={project.id} className="project-item">
                  <h3>{project.name}</h3>
                  <p>Status: {project.status}</p>
                  <p>Due Date: {project.dueDate}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No projects found for this account.</p>
          )}
        </div>

        <div className="dashboard-section">
          <h2>Billing Overview</h2>
          {billingInfo ? (
            <div className="billing-overview">
              <p>Total Amount: ${billingInfo.totalAmount}</p>
              <p>Escrow Balance: ${billingInfo.escrowBalance}</p>
              <p>Released Amount: ${billingInfo.releasedAmount}</p>
            </div>
          ) : (
            <p>No billing information available.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
