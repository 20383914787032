import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'; // Use Navigate for redirects
import './App.css';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Login from './components/LandingPage/Login'; // Adjust the path if necessary
import ForgotPassword from './components/LandingPage/ForgotPassword'; // Adjust the path if necessary
import SignUp from './components/LandingPage/SignUp'; // Adjust the path if necessary 
import Dashboard from './components/Dashboard/Dashboard'; // Adjust the path if necessary
import Projects from './components/Projects/Projects'; // Adjust the path if necessary
import Billing from './components/Billing/Billing'; // Adjust the path if necessary
import Settings from './components/Settings/Settings'; // Adjust the path if necessary

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Optional: Add a spinner or loading screen
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Default Route for Login */}
          <Route path="/" element={user ? <Navigate to="/dashboard" /> : <Login />} />
          {/* Route for Forgot Password */}
          <Route path="/forgot-password" element={<ForgotPassword />} />
          {/* Route for Sign Up */}
          <Route path="/sign-up" element={<SignUp />} />
          {/* Protected Route for Dashboard */}
          <Route
            path="/dashboard"
            element={user ? <Dashboard /> : <Navigate to="/" />}
          />
          {/* Protected Route for Projects */}
          <Route
            path="/projects"
            element={user ? <Projects /> : <Navigate to="/" />}
            /> 
            {/* Protected Route for Billing */}
            <Route
            path="/billing"
            element={user ? <Billing /> : <Navigate to="/" />}

          />  

                {/* Protected Route for Settings */}
                <Route
            path="/settings"
            element={user ? <Settings /> : <Navigate to="/" />}

          />  
          {/* Fallback Route */}
          <Route path="*" element={<div>404 - Page Not Found</div>} />
        
        </Routes>
      </div>
    </Router>
  );
}

export default App;
