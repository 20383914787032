import React, { useState, useEffect } from 'react';
import Sidebar from '../Sidebar/Sidebar'; // Adjust the path if necessary
import '../Dashboard/Dashboard.css'; // Shared styles from Dashboard
import './Billing.css'; // Specific styles for Billing
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import { app } from '../../firebase/firebaseConfig'; // Firebase configuration

function Billing() {
  const [billingData, setBillingData] = useState([]); // Store all billing information
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalData, setModalData] = useState(null); // Data for the modal
  const [modalProjectName, setModalProjectName] = useState(''); // Project name for the modal
  const auth = getAuth(app);
  const db = getFirestore(app);

  useEffect(() => {
    const fetchBillingData = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          throw new Error('No authenticated user found.');
        }

        const userId = user.uid; // Get the current user's ID

        // Query the Billing collection to fetch only the billing records tied to this user
        const billingRef = collection(db, 'Billing');
        const q = query(billingRef, where('clientId', '==', userId)); // Filter by clientId
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          throw new Error('No billing information found for this client.');
        }

        const billingDetails = [];
        for (const billingDoc of querySnapshot.docs) {
          const billingData = billingDoc.data();

          // Fetch the project name based on projectId in the billing document
          const projectDocRef = doc(db, 'Projects', billingData.projectId);
          const projectDoc = await getDoc(projectDocRef);

          const projectName = projectDoc.exists() ? projectDoc.data().name : 'Unknown Project';

          billingDetails.push({ id: billingDoc.id, ...billingData, projectName });
        }

        setBillingData(billingDetails);
      } catch (error) {
        console.error('Error fetching billing info:', error);
        setErrorMessage(error.message || 'Failed to fetch billing information.');
      } finally {
        setLoading(false);
      }
    };

    fetchBillingData();
  }, [auth, db]);

  const openModal = (milestones, projectName) => {
    setModalData(milestones);
    setModalProjectName(projectName);
  };

  const closeModal = () => {
    setModalData(null);
    setModalProjectName('');
  };

  const handleReleasePayment = async (milestoneId, billingId) => {
    try {
      const billing = billingData.find((b) => b.id === billingId);
      const updatedMilestones = billing.milestones.map((milestone) =>
        milestone.milestoneId === milestoneId
          ? { ...milestone, status: 'Released', releaseDate: new Date().toISOString() }
          : milestone
      );

      const billingDocRef = doc(db, 'Billing', billingId);
      await updateDoc(billingDocRef, { milestones: updatedMilestones });

      setBillingData((prev) =>
        prev.map((b) =>
          b.id === billingId ? { ...b, milestones: updatedMilestones } : b
        )
      );

      alert(`Payment for milestone "${milestoneId}" has been released.`);
    } catch (error) {
      console.error('Error releasing payment:', error);
      alert('Failed to release payment. Please try again.');
    }
  };

  if (loading) {
    return <div className="dashboard-container">Loading...</div>;
  }

  if (errorMessage) {
    return (
      <div className="dashboard-container">
        <div className="error-message">{errorMessage}</div>
      </div>
    );
  }

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="dashboard-content">
        <h1 className="dashboard-title">Billing</h1>
        <div className="billing-container">
          {billingData.map((billing) => (
            <div key={billing.id} className="billing-info-container">
              <h2>Project: {billing.projectName}</h2>
              <p>Total Amount: ${billing.totalAmount}</p>
              <p>Released Amount: ${billing.releasedAmount}</p>

              <button className="fund-project-button">Fund Project</button>

              <button
                className="see-all-milestones-button"
                onClick={() => openModal(billing.milestones, billing.projectName)}
              >
                See All Milestones
              </button>
            </div>
          ))}
        </div>

        {/* Modal */}
        {modalData && (
          <div className="modal-overlay">
            <div className="modal-container">
              <button className="modal-close-button" onClick={closeModal}>
                &times;
              </button>
              <h2>Milestones for {modalProjectName}</h2>
              <ul className="milestone-list">
                {modalData.map((milestone, index) => (
                  <li key={index} className="milestone-item">
                    <h4>{milestone.name}</h4>
                    <p>Amount: ${milestone.amount}</p>
                    <p>Status: {milestone.status}</p>
                    <p>Release Date: {milestone.releaseDate || 'Not Released'}</p>
                    {milestone.status !== 'Released' && (
                      <button
                        className="release-payment-button"
                        onClick={() => handleReleasePayment(milestone.milestoneId, billingData.find((b) => b.projectName === modalProjectName).id)}
                      >
                        Release Payment
                      </button>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Billing;
