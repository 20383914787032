import React from 'react';
import './Sidebar.css';
import logo from '../../assets/logo.svg';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';

function Sidebar() {
  const navigate = useNavigate();

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      console.log('Logout successful!');
      navigate('/'); // Redirect to login page after logout
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <div className="sidebar">
      {/* Logo Section */}
      <div className="sidebar-logo">
        <Link to="/">
          <img src={logo} alt="Logo" className="sidebar-logo-img" />
        </Link>
      </div>

      {/* Navigation Links */}
      <nav className="sidebar-nav">
        <ul>
          <li>
            <Link to="/dashboard" className="sidebar-link">Dashboard</Link>
          </li>
          <li>
            <Link to="/projects" className="sidebar-link">Projects</Link>
          </li>
          <li>
            <Link to="/billing" className="sidebar-link">Billing</Link>
          </li>
          <li>
            <Link to="/settings" className="sidebar-link">Settings</Link>
          </li>
        </ul>
      </nav>

      {/* Logout Button */}
      <div className="sidebar-logout">
        <button onClick={handleLogout} className="logout-button">Log Out</button>
      </div>
    </div>
  );
}

export default Sidebar;
