import React, { useState } from 'react';
import './LandingPage.css';
import logo from '../../assets/logo.svg';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent! Please check your inbox.');
      setError(''); // Clear any existing errors
    } catch (error) {
      setError('Failed to send password reset email. Please try again.');
      console.error('Password reset error:', error);
      setMessage(''); // Clear any existing success message
    }
  };

  return (
    <div className="landing-page">
{/* Header Section */}
<div className="landing-header">
  <a href="/" className="logo-link">
    <img src={logo} alt="Logo" className="landing-logo" />
  </a>
  <div className="landing-text">
    <h1>AigencyOne Technology</h1>
    <h2>Client Portal</h2>
  </div>
</div>


      {/* Main Content Section */}
      <div className="landing-main">
        <div className="login-container">
          <h2 className="login-title">Forgot Password</h2>
          <form className="login-form" onSubmit={handleForgotPassword}>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="login-button">Send Reset Link</button>
          </form>

          {/* Display Success or Error Messages */}
          {message && <p className="success-message">{message}</p>}
          {error && <p className="error-message">{error}</p>}

          <div className="login-footer">
            <p>
              <a href="/" className="forgot-password-link">Return to Home</a> {/* Updated to navigate to "/" */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
